import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RenderProfileActivityEvent from '@shared/ui/display/table-renderers/RenderProfileActivityEvent';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn, ContainerRow, SurfaceColumn } from '@src/components/styled';
import { BodyMedium, Title as TitleComponent } from '@src/components/styled/Typography';
import Table from '@shared/ui/display/Table';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import { useGetProfileActivityQuery } from '@shared/api/current-user';
import withLazyLoading from '@shared/ui/display/Table/withLazyLoading';
import { IsDefined } from '@utils/js-ts';
import { ActivityTableRow, activityRenderer } from './table';
const MinHeight = 486;
const Container = styled(ContainerColumn)(props => ({
    flex: 1,
    margin: `${props.theme.spacing_sizes.l}px 0`,
}));
const TableContainer = styled(SurfaceColumn)(props => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
    padding: `${props.theme.spacing_sizes.s * 5}px ${props.theme.spacing_sizes.l}px`,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const Title = styled(TitleComponent)(() => ({
    fontSize: 18,
    lineHeight: '38px',
    letterSpacing: 'unset',
}));
const TitleContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    minHeight: MinHeight,
    alignItems: 'center',
    justifyContent: 'center',
}));
const ActivityInfo = styled(BodyMedium)(props => ({
    fontSize: 12,
    color: props.theme.palette.text.secondary,
    alignSelf: 'center',
}));
const ColumnsDefinition = [
    {
        accessorKey: 'activity',
        meta: {
            align: 'left',
        },
        cell: activityRenderer(tableRendererWrapper(RenderProfileActivityEvent)),
    },
];
const TableWithLazyLoading = withLazyLoading(Table);
const ActivityTab = () => {
    const { t } = useTranslation();
    const { data, isFetching } = useGetProfileActivityQuery();
    const { areDevComponentsEnabled } = useAppSelector((state) => state.app);
    const columnsDefinition = useMemo(() => ColumnsDefinition, []);
    const dataFormatted = useMemo(() => {
        let result = [];
        if (IsDefined(data)) {
            Object.values(data).forEach((activityElem) => {
                var _a;
                if ((_a = activityElem.positions) === null || _a === void 0 ? void 0 : _a.length) {
                    result.push({
                        date: activityElem.date,
                    });
                }
                result = result.concat(activityElem.positions);
            });
        }
        return result;
    }, [data]);
    return (_jsx(Container, { children: _jsxs(TableContainer, { children: [_jsxs(TitleContainer, { children: [_jsx(Title, { children: t('profile.tabs.activity') }), _jsx(ActivityInfo, { children: t('profile.tabs.activity_show_last') })] }), areDevComponentsEnabled && (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }) })), IsDefined(data) && (_jsx(TableWithLazyLoading, { columnDef: columnsDefinition, data: areDevComponentsEnabled && dataFormatted ? dataFormatted : [], onLoadMore: () => { }, components: {
                                BodyRow: ActivityTableRow,
                            }, isLoading: isFetching, hasMore: true, hideHeader: true }))] }))] }) }));
};
export default ActivityTab;
